/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { HierarchyTreeContext } from '../../../providers/HierarchyTreeProvider';
import {
  Checkbox,
  OverflowMenu,
  OverflowMenuItem,
  InlineLoading,
  Tag,
} from '@carbon/react';
import {
  ChevronDown,
  ChevronRight,
  PinFilled,
  Warning,
  Locked,
} from '@carbon/icons-react';
import {
  getSelectedChildren,
  hasMatchingDescendant,
  renderNodeLabel,
} from '../../../utils';
import HierarchyTree from './HierarchyTree';
import type { SelectionObject } from '../ReportBuilder';
import {
  getInvalidNode,
  getNodeId,
  selectChildLevel,
} from '../utils/hierarchyUtils';
import '../../../styles/components/hierarchy.scss';
import { ModalContext } from '../../../providers/ModalProvider';
import EditNodeName from './EditNodeName';
import usePosthog from '../../../utils/posthog';
import {
  reportBuilderHierarchyNodeClick,
  reportBuilderOverflowLevelClick,
  reportBuilderOverflowOneLevelClick,
} from '../../../constants/posthog';
import Tooltip from '../../Tooltip';
import { AppContext } from '../../../providers/AppProvider';
import { CustomGroupAccess } from '../../../constants/metadata';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import { getFormattedNodeLabel } from '../utils/reportBuilderUtils';

interface HierarchyNodeProps {
  node: HierNode;
  excludeSelect: boolean;
  searchTerm: string;
  selection: SelectionObject;
  error?: boolean;
  updateSelection?: (node: HierNode) => void;
  updateSelections?: (level: string, selections: HierNode[]) => void;
  isShouldBeExpanded?: boolean;
  nodeLevel?: number;
  parentNode?: HierNode;
  selectedHierarchy?: Hierarchy;
  levels: { [key: string]: HierarchyLevel };
  deleteFavourite?: (
    type: 'group' | 'item',
    name: string,
    parentName?: string
  ) => Promise<void>;
}

const HierarchyNode: FunctionComponent<HierarchyNodeProps> = ({
  node,
  excludeSelect,
  searchTerm,
  error,
  selection,
  updateSelection,
  updateSelections,
  isShouldBeExpanded,
  nodeLevel,
  parentNode,
  selectedHierarchy,
  levels,
  deleteFavourite,
}) => {
  const posthogEvent = usePosthog();
  const { getNodeState, updateNodeState } = useContext(HierarchyTreeContext);
  const { user } = useContext(AppContext);
  const [isChildrenExpanded, setIsChildrenExpanded] = useState(false);
  const [filteredChildren, setFilteredChildren] = useState(node.children);
  const [isLoading, setIsLoading] = useState(false);
  const checked = selection[node.level]
    ? selection[node.level].map((n) => n.id).includes(node.id)
    : false;
  const nodeId = getNodeId(node);
  const nodeStateId = `${getNodeId(node)}${
    node.favouriteNodeType || nodeLevel ? '_favourite' : ''
  }`;
  const expanded = getNodeState(nodeStateId);
  const { updateModal } = useContext(ModalContext);
  const level = nodeLevel || Number(node.level);
  const customGroupOwnerName = node.user_id === user?.id ? 'Me' : node.owner;
  const nodeAccess = !node.favouriteNodeType && node.access;
  const nodeOwner = !node.favouriteNodeType && customGroupOwnerName;
  const nodeLabel =
    node.favouriteNodeType || nodeAccess || nodeOwner
      ? node.label
      : renderNodeLabel(node.label);
  const formattedNodeLabel = getFormattedNodeLabel(nodeLabel, node.count);
  const hideChildren = node.favouriteNodeType === 'favourite_parameter';
  const isNodeInvalid = getInvalidNode(node);

  useEffect(() => {
    setFilteredChildren(node.children);
  }, [node.children]);

  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredChildren(node.children);
    } else if (searchTerm.length > 3) {
      updateNodeState(nodeStateId, hasMatchingDescendant(node, searchTerm));

      const children =
        node.children &&
        node.children.filter((child) => {
          return hasMatchingDescendant(child, searchTerm);
        });

      setFilteredChildren(children);
    }
  }, [searchTerm]);

  const childrenFilteredOut = () => {
    return expanded && filteredChildren && filteredChildren.length === 0;
  };

  const classNames = [
    'hierarchy-node',
    error ? 'error' : 'hoverable clickable',
    checked && 'checked',
    isNodeInvalid && 'invalid',
  ].filter((s) => !!s);

  const selectChildren = () => {
    const { nodeLevel, selections } = getSelectedChildren(node) ?? {};
    posthogEvent(reportBuilderOverflowOneLevelClick, {
      nodeLevel,
    });
    updateNodeState(nodeStateId, true);
    setIsChildrenExpanded(false);
    if (updateSelections && nodeLevel && selections) {
      updateSelections(nodeLevel, selections);
    }
  };

  useEffect(() => {
    if (isShouldBeExpanded) {
      updateNodeState(nodeStateId, true);
    }
  }, [isShouldBeExpanded]);

  const selectLevel = (node: HierNode, level: string) => {
    const { nodeLevel, selections } = selectChildLevel(node, level);
    expandNodeToLevel(node, level);
    posthogEvent(reportBuilderOverflowLevelClick, {
      level,
    });
    updateSelections?.(nodeLevel, selections);
  };

  const handleDeleteFavourites = async (type: 'group' | 'item') => {
    setIsLoading(true);
    if (parentNode?.children?.length === 1 && type === 'item') {
      await deleteFavourite?.('group', parentNode.label);
    } else {
      await deleteFavourite?.(type, node.label, parentNode?.label);
    }
    setIsLoading(false);
  };

  const handleDeleteNode = (type: 'group' | 'item') => {
    updateModal({
      type: 'warning',
      title: 'Favourite to be deleted',
      body: (
        <>
          You have selected a favourite to be deleted permanently. Do you want
          to continue?
        </>
      ),
      primaryCTAText: 'Continue',
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: () => handleDeleteFavourites(type),
    });
  };

  const expandNodeToLevel = (expandingNode: HierNode, level: string) => {
    if (expandingNode.level < level) {
      updateNodeState(getNodeId(expandingNode), true);
      expandingNode.children?.forEach((child) => {
        expandNodeToLevel(child, level);
      });
    }
  };

  const overflowMenuFavouritesOptions = {
    favourite_parameter: [
      {
        label: 'Delete',
        onClick: () => handleDeleteNode('item'),
      },
    ],
    favourite_group: [
      {
        label: 'Delete group',
        onClick: () => handleDeleteNode('group'),
      },
      {
        label: 'Select one level below',
        onClick: () => selectChildren(),
      },
    ],
    favourite_title: [],
  };

  const overflowMenuOptions =
    (node.favouriteNodeType &&
      overflowMenuFavouritesOptions[node.favouriteNodeType]) ||
    (node.children
      ? Object.keys(levels)
          .filter((key) => {
            const levelCheckCondition = node.levelId
              ? Number(key) === Number(node.levelId)
              : Number(key) > level;
            return level >= levels[key].selectThreshold && levelCheckCondition;
          })
          .map((key) => {
            return {
              label: `Select ${levels[key].label}`,
              onClick: () => {
                selectLevel(node, key.toString());
              },
            };
          })
      : []);

  return (
    <>
      <div
        data-testid={`hierarchy-node-${node.id ?? node.label}`}
        className={classNames.join(' ')}
        style={{
          paddingLeft: 16 * level + 'px',
        }}
        onClick={() => {
          posthogEvent(reportBuilderHierarchyNodeClick, {
            id: node.id,
            label: node.label,
            level: node.level,
          });
          updateNodeState(nodeStateId, !expanded);
        }}
      >
        <div className="hierarchy-node-body">
          <div
            className={`hierarchy-label-container ${
              node.id === 'Favourites' ? 'favourites' : ''
            }`}
          >
            {!!node.children && !hideChildren && (
              <div className="expand-chevron">
                {expanded ? <ChevronDown /> : <ChevronRight />}
              </div>
            )}

            {node.favouriteNodeType === 'favourite_group' &&
            selectedHierarchy ? (
              <EditNodeName
                node={node}
                hierarchyName={selectedHierarchy.name}
              />
            ) : (
              <span>
                {formattedNodeLabel}
                {node.id === 'Favourites' && <PinFilled size={18} />}
              </span>
            )}
          </div>
          {nodeAccess === CustomGroupAccess.PRIVATE && (
            <Tooltip
              description="Private groups are only visible and accesible to the owner"
              useButtonWrapper
            >
              <Tag
                type="blue"
                size="sm"
                renderIcon={Locked}
                className="hierarchy-node-access"
              >
                {capitalizeFirstLetter(nodeAccess)}
              </Tag>
            </Tooltip>
          )}

          <div
            onClick={(e) => e.stopPropagation()}
            className={
              node.children || node.favouriteNodeType
                ? 'node-actions'
                : 'node-checkbox'
            }
          >
            {!excludeSelect && !isNodeInvalid && (
              <Checkbox
                data-testid="hierarchy-node-checkbox"
                hideLabel
                labelText=""
                id={nodeId}
                checked={checked}
                onChange={() => updateSelection?.(node)}
              />
            )}

            {isNodeInvalid && (
              <Tooltip
                align="left"
                description="The ID assigned to this item no longer exists. You can delete this parameter from your Favourites."
                useButtonWrapper
              >
                <Warning />
              </Tooltip>
            )}
            {!!overflowMenuOptions.length && !isLoading && (
              <OverflowMenu
                ariaLabel={`overflow-menu-${node.id}`}
                data-testid={`overflow-menu-${node.id}`}
                focusTrap={false}
              >
                {overflowMenuOptions.map(({ label, onClick }) => (
                  <OverflowMenuItem
                    key={label}
                    itemText={label}
                    onClick={onClick}
                  />
                ))}
              </OverflowMenu>
            )}
            {isLoading && <InlineLoading />}
          </div>
        </div>
        {nodeOwner && nodeAccess && (
          <span style={{ paddingLeft: '20px' }}>Owner: {nodeOwner}</span>
        )}
      </div>
      {filteredChildren && expanded && !hideChildren && selectedHierarchy && (
        <HierarchyTree
          nodes={filteredChildren}
          searchTerm={searchTerm}
          selection={selection}
          updateSelection={updateSelection}
          updateSelections={updateSelections}
          isShouldBeExpanded={isChildrenExpanded}
          nodeLevel={nodeLevel}
          parentNode={node}
          selectedHierarchy={selectedHierarchy}
          deleteFavourite={deleteFavourite}
          levels={levels}
        />
      )}

      {childrenFilteredOut() && (
        <HierarchyNode
          key={`error-${node.id}`}
          node={{
            id: `error-${node.id}`,
            label: 'No items match the current search',
            level: '1',
          }}
          error
          excludeSelect={true}
          searchTerm=""
          selection={{}}
          levels={levels}
        />
      )}
    </>
  );
};

export default HierarchyNode;
