import { Button, Column, Grid, Heading } from '@carbon/react';
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../providers/AppProvider';
import { HomeContext } from '../../providers/HomeProvider';
import { PosthogContext } from '../../providers/PosthogProvider';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { formatUTCDate } from '../../utils/DateUtils';
import Maintenance from '../Maintenance/Maintenance';
import HomeData from './HomeData';
import AI from '../../assets/icons/Ai';
import GenAIPanel from '../GenAIPanel/GenAIPanel';
import '../../styles/components/home.scss';
import {
  getDataFileKey,
  getGenAIConfigFilePath,
} from '../../utils/reportUtils';
import { useClientFeatureFlags } from '../../hooks';
import type { Visual } from '../../reducers/ReportReducer';
import { CLIENT_FLAGS } from '../../reducers/ClientFeatureFlagsReducer';

const Home: FunctionComponent = () => {
  const { bannerId, groupId, user } = useContext(AppContext);
  const {
    periodEnd,
    resetAllHomeData,
    selectedCategory,
    homeDropdownSelections,
    reportConfigMap,
  } = useContext(HomeContext);
  const { reportConfig, dropdownSelections } = useContext(ReportContext);
  const { featureFlags } = useContext(PosthogContext);
  const { renderSidePanelContent, expanded } = useContext(SidePanelContext);
  const { isFlagEnabled } = useClientFeatureFlags();

  const reportsToDisplay = useMemo(
    () =>
      (homeDropdownSelections &&
        reportConfigMap[getDataFileKey(homeDropdownSelections)]) ||
      [],
    [homeDropdownSelections, reportConfigMap]
  );

  const reportConfigFiles: string[] = useMemo(
    () =>
      reportsToDisplay.reduce((acc, reportId) => {
        const dropdownSelection = dropdownSelections?.[`${reportId}_0`];

        if (!dropdownSelection) {
          return acc;
        }

        const visuals = reportConfig.configuration.visuals[reportId]
          .flatMap((visual) =>
            visual.container ? visual.visuals ?? [] : visual
          )
          .map(({ apiUrl }: Visual) =>
            getGenAIConfigFilePath(apiUrl as string, dropdownSelection)
          );

        return [...acc, ...visuals];
      }, [] as string[]),
    [dropdownSelections, reportsToDisplay]
  );

  useEffect(() => {
    resetAllHomeData();
  }, [bannerId, groupId]);

  const isDataReady = !!selectedCategory && reportConfigFiles.length > 0;
  const isAIVisible = isFlagEnabled(CLIENT_FLAGS.GENAI) && isDataReady;

  const homepageFlag = featureFlags.find(
    ({ name, payload }) =>
      name === 'homepage-maintenance' &&
      payload &&
      bannerId &&
      (payload as { banner_ids?: number[] }).banner_ids?.includes(bannerId)
  );

  useEffect(() => {
    if (expanded) {
      handleGenAIPanelInSidePanel();
    }
  }, [selectedCategory, reportConfigFiles]);

  const handleGenAIPanelInSidePanel = () => {
    renderSidePanelContent(
      <GenAIPanel
        selectedCategory={selectedCategory as HierNode}
        configFiles={reportConfigFiles}
      />,
      null,
      false
    );
  };

  if (homepageFlag) {
    const { title, text } = homepageFlag.payload as {
      title: string;
      text: string;
      banner_ids?: number[];
    };
    return (
      <Maintenance header={title} subheader={text} hasBackButton={false} />
    );
  } else {
    return (
      <>
        <Column className="Home__heading-container">
          <div className="flex align-start justify-space-between">
            <Heading className="home-heading">
              Welcome back, {user?.name?.split(' ')[0]}!
            </Heading>
            {isAIVisible && (
              <Button
                kind="primary"
                className="has-icon"
                size="md"
                renderIcon={AI}
                onClick={handleGenAIPanelInSidePanel}
              >
                Ask AI
              </Button>
            )}
          </div>
          <Grid>
            <Column lg={8} md={4} sm={4} className="helper-text helper-text-01">
              <span>
                Data available up to:{' '}
                {periodEnd ? formatUTCDate(periodEnd, false) : '-'}
              </span>
            </Column>
          </Grid>
        </Column>
        <HomeData />
      </>
    );
  }
};

export default Home;
