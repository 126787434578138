import { createRoot } from 'react-dom/client';
import posthog from 'posthog-js';
import AppProvider from './providers/AppProvider';
import ConfiguredAuthProvider from './providers/ConfiguredAuthProvider';
import PosthogProvider from './providers/PosthogProvider';
import ClientFeatureFlagsProvider from './providers/ClientFeatureFlagProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY as string, {
  api_host: 'https://app.posthog.com',
});

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <ConfiguredAuthProvider>
    <AppProvider>
      <PosthogProvider>
        <ClientFeatureFlagsProvider>
          <App />
        </ClientFeatureFlagsProvider>
      </PosthogProvider>
    </AppProvider>
  </ConfiguredAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
