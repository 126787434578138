/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  Tabs,
  Tab,
  TabList,
} from '@carbon/react';
import {
  DocumentDownload,
  DocumentView,
  TaskAdd,
  Share,
  ChevronLeft,
} from '@carbon/icons-react';
import { AppContext } from '../../providers/AppProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { VisualType, type Visual } from '../../reducers/ReportReducer';
import Fetch from '../Fetch';
import ReportMetaData from './ReportMetaData';
import SharedDropdowns from './Dropdowns/SharedDropdowns';
import { CACHE_KEY } from '../../constants/api';
import ConditionalWrapper from '../ConditionalWrapper';
import { assortmentWrapper } from '../../providers/AssortmentProvider';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import { ModalContext } from '../../providers/ModalProvider';
import ShareReport from '../ShareReport/ShareReport';
import ExportOverflow from '../Charts/ExportOverflow';
import Tooltip from '../Tooltip';
import {
  isVisualFullscreen,
  findVisualById,
  shouldShowExportAll,
} from '../../utils/reportUtils';
import {
  duplicateReportClick,
  editReportClick,
  reportContentSwitcherClick,
  reportDetailsClick,
  shareReportClick,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import {
  ALL_VISUALS_KEY,
  SHARED_DROPDOWN_KEY,
  VISUAL_CONTAINER_KEY,
} from '../../constants/values';
import ReportContentNavigationTabs from '../ReportContentNavigationTabs';
import VisualContainer from './VisualContainer/VisualContainer';
import { getIcon } from '../../utils/iconUtils';
import ReportCopyModal from './ReportCopyModal';
import { getSelectedItem } from '../../utils';
import { NPDWrapper } from '../../providers/NPDProvider';

export interface GlobalSelection {
  label: string;
  selectedKey: string | number;
  isExportUniqueKey?: boolean;
}

const ReportContent: FunctionComponent = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { bannerId, groupId, user } = useContext(AppContext);
  const {
    visualRefs,
    visualsData,
    tabIndex,
    reportConfig,
    fullscreenVisual,
    dropdownSelections,
    sharedDropdowns,
    toggleVisualFullscreen,
    updateSharedDropdowns,
    updateTabIndex,
    updateReportConfig,
    updateFullscreenRef,
  } = useContext(ReportContext);
  const { renderSidePanelContent } = useContext(SidePanelContext);
  const { renderComponent } = useContext(ModalContext);
  const posthogEvent = usePosthog();
  const [visualMetaData, setVisualMetaData] = useState<Visual[]>([]);
  const [globalSelections, setGlobalSelections] = useState<GlobalSelection[]>(
    []
  );
  const reportTemplateIndex = 0;
  const reportTemplateId = `${reportConfig.parameters.template_id}_${reportTemplateIndex}`;
  const [searchParameters, setSearchParameters] = useSearchParams();
  const skuId = searchParameters.get('sku_id');
  const isAggReport =
    reportConfig.report_type === 'Consolidated Performance Report';
  const isSkuPageSelected = skuId && isAggReport;

  const headerPrefix = useMemo(() => {
    const productDropdown = sharedDropdowns[tabIndex]?.find(
      ({ label }) => label === 'Product'
    );
    if (
      !isAggReport ||
      !productDropdown ||
      !dropdownSelections[tabIndex] ||
      !visualMetaData[0]
    ) {
      return '';
    }
    const selectedItem = getSelectedItem(
      productDropdown.id,
      dropdownSelections[tabIndex][visualMetaData[0].id],
      sharedDropdowns[tabIndex]
    );

    return selectedItem ? `${selectedItem} - ` : '';
  }, [sharedDropdowns[tabIndex], dropdownSelections, isAggReport]);

  const {
    report_name,
    configuration,
    report_type,
    section,
    url_route,
    run_id,
  } = reportConfig;

  const isTabsVisible =
    configuration.switchers[reportTemplateId]?.length > 1 && !isAggReport;

  useEffect(() => {
    if (isSkuPageSelected) {
      setGlobalSelections([{ label: 'Product', selectedKey: skuId }]);
      updateTabIndex(1);
    }
    if (!skuId && isAggReport) {
      setGlobalSelections([]);
      updateTabIndex(0);
    }
  }, [skuId, isAggReport]);

  useEffect(() => {
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        toggleVisualFullscreen(null);
      }
    };

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  useEffect(() => {
    if (configuration?.switchers[reportTemplateId]) {
      const visualIdsToDisplay =
        configuration.switchers[reportTemplateId][tabIndex]?.visualContent;

      if (visualIdsToDisplay) {
        const visualsToDisplay = configuration.visuals[reportTemplateId].filter(
          (vis) => visualIdsToDisplay.includes(vis.id)
        );

        setVisualMetaData(visualsToDisplay);
      }
    }
  }, [tabIndex, configuration.switchers.length, reportConfig]);

  useEffect(() => {
    const sharedDropdown = configuration?.switchers?.[reportTemplateId]?.[
      tabIndex
    ]?.visualContent?.map((id) => {
      const visualDropdownSelection = dropdownSelections?.[tabIndex]?.[id];
      const sharedDropdowns = visualsData?.[id]?.sharedDropdown ?? [];
      const fileSharedDropdowns =
        visualsData?.[id]?.files?.[visualDropdownSelection]?.sharedDropdown ??
        [];
      return [...sharedDropdowns, ...fileSharedDropdowns];
    })?.[0];

    updateSharedDropdowns(tabIndex, sharedDropdown);
  }, [tabIndex, visualsData, reportConfig, dropdownSelections]);

  const handleEdit = () => {
    posthogEvent(editReportClick, {
      origin: 'Report details',
      reportType: url_route,
    });
    navigate(`/modules/${section}/${url_route}?edit=${run_id}`);
  };

  const handleDuplicate = () => {
    posthogEvent(duplicateReportClick, {
      origin: 'Report details',
      reportType: url_route,
    });
    navigate(`/modules/${section}/${url_route}?duplicate=${run_id}`);
  };

  const handleViewMoreClick = () => {
    posthogEvent(reportDetailsClick, {
      origin: 'Report details',
      reportType: url_route,
    });
    renderSidePanelContent(
      <ReportMetaData
        reportConfig={reportConfig}
        handleEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        reportTemplateIndex={reportTemplateIndex}
      />,
      null,
      false
    );
  };

  const handleShareClick = () => {
    posthogEvent(shareReportClick, {
      origin: 'Report details',
      reportType: url_route,
    });
    if (bannerId && groupId) {
      renderSidePanelContent(
        <ShareReport
          bannerId={bannerId}
          groupId={groupId}
          report={reportConfig}
        />,
        { title: 'Share report', subtitle: report_name },
        true
      );
    }
  };

  useEffect(() => {
    updateFullscreenRef(fullscreenRef);
  }, [fullscreenVisual]);

  const fullscreenRef = useRef<HTMLDivElement>(null);
  const isAuthor = reportConfig.user_id === user?.id;
  const isCDTReport = report_type === 'Customer Decision Tree';
  const isNPDFinderReport = report_type === 'NPD Finder';
  const hideSharedDropdowns =
    (isCDTReport && isAuthor && user.user_type !== 'supplier') ||
    isNPDFinderReport;
  const showExportAll =
    reportConfig.configuration.switchers?.[reportTemplateId]?.length > 0 &&
    shouldShowExportAll(reportConfig, tabIndex, reportTemplateId);

  const conditionalWrapperProps: {
    condition: boolean;
    wrapper?: (children: JSX.Element[]) => JSX.Element;
  } = useMemo(() => {
    switch (report_type) {
      case 'Customer Decision Tree':
        return {
          condition: true,
          wrapper: (children: JSX.Element[]) =>
            assortmentWrapper(children, reportTemplateId),
        };
      case 'NPD Finder':
        return {
          condition: true,
          wrapper: (children: JSX.Element[]) =>
            NPDWrapper(children, reportTemplateId),
        };
      default:
        return { condition: false };
    }
  }, [report_type, reportTemplateId]);

  const handleNavButtonClick = (id: string) => {
    const appContainer = document.querySelector('#root > .app');
    const { top } = (
      visualRefs?.current?.[id] as HTMLDivElement
    ).getBoundingClientRect();
    appContainer?.scrollTo({
      top: top + appContainer.scrollTop - 55,
      behavior: 'smooth',
    });
  };

  const goBack = () => {
    searchParameters.delete('sku_id');
    setSearchParameters(searchParameters);
  };

  const navigationTabs = visualMetaData.filter(
    ({ navigationTab }) => !!navigationTab
  );

  const getNavigationTabs = (visual: Visual) => {
    const { id, container, visuals } = visual;
    if (navigationTabs.length === 0) {
      return null;
    }
    const tabs = navigationTabs
      .filter(({ navigationTab }) => navigationTab)
      .map(({ id, navigationTab }) => {
        const { label, icon } = navigationTab as NonNullable<
          typeof navigationTab
        >;
        return {
          icon: icon ? getIcon(icon) : undefined,
          id: `${VISUAL_CONTAINER_KEY}-${id}`,
          label,
        };
      });

    const isVisualReady =
      container && visuals ? visualsData[visuals[0].id] : visualsData[id];

    return isVisualReady ? (
      <ReportContentNavigationTabs
        tabs={tabs}
        onTabClick={handleNavButtonClick}
      />
    ) : null;
  };

  const getChartElementRefs = (): HTMLDivElement[] | null => {
    const { switchers, visuals } = reportConfig.configuration;
    if (!visualRefs?.current) {
      return null;
    }
    const visualsToExport = switchers[reportTemplateId][tabIndex].visualContent
      .filter((visualId) => {
        const visual = findVisualById(visuals[reportTemplateId], visualId);
        return (
          visual &&
          !visual.container &&
          ![VisualType.CELL_GRID, VisualType.SUMMARY_GRID].includes(
            visual.type as VisualType
          )
        );
      })
      .map((visualId) => `${VISUAL_CONTAINER_KEY}-${visualId}`);
    const refs = visualsToExport.map(
      (id) => visualRefs.current?.[id] as HTMLDivElement
    );
    return [visualRefs.current[`${SHARED_DROPDOWN_KEY}-${tabIndex}`], ...refs];
  };

  return (
    <>
      <Fetch
        key={reportId}
        apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?run_id=${reportId}`}
        cacheKey={`${CACHE_KEY.REPORT}-${reportId}`}
        initialData={null}
        loadingMessage="Loading report metadata..."
        hideChildrenUntilFetched
        alwaysFetchOnMount={!reportConfig.run_id}
        onReceiveData={(data) => {
          if (data) {
            updateReportConfig({ ...data[0] });
          }
        }}
      >
        <Breadcrumb noTrailingSlash>
          <BreadcrumbItem onClick={() => navigate('/workspace/my-reports')}>
            {`Workspace / ${report_type}`}
          </BreadcrumbItem>
        </Breadcrumb>
        {reportConfig && (
          <div className="report-details-container">
            {isSkuPageSelected && (
              <Button
                kind="ghost"
                iconDescription="Go back"
                renderIcon={ChevronLeft}
                hasIconOnly
                size="sm"
                disabled={!run_id}
                onClick={goBack}
              />
            )}
            <h3 className="heading-06">
              {isSkuPageSelected ? 'SKU Performance' : report_name}
            </h3>

            <Tooltip description="Report summary">
              <Button
                kind="secondary"
                renderIcon={DocumentView}
                hasIconOnly
                iconDescription="Report summary"
                data-testid="report-details-button"
                onClick={handleViewMoreClick}
                size="sm"
              />
            </Tooltip>
            {isCDTReport && (
              <Tooltip description="Create copy">
                <Button
                  kind="secondary"
                  renderIcon={TaskAdd}
                  hasIconOnly
                  iconDescription="Report copy"
                  data-testid="report-copy-button"
                  onClick={() =>
                    renderComponent(
                      <ReportCopyModal
                        key={report_name}
                        reportName={report_name}
                        runId={run_id}
                      />,
                      'ReportCopyModal'
                    )
                  }
                  size="sm"
                />
              </Tooltip>
            )}
            {showExportAll && (
              <ExportOverflow
                elements={getChartElementRefs()}
                className="report-action-button"
                icon={DocumentDownload}
                report_name={report_name}
                alignTooltip="top"
                tooltipText="Download all charts"
                size="sm"
                disabled={!visualRefs?.current?.[ALL_VISUALS_KEY]}
              />
            )}
            {isAuthor && (
              <Tooltip description="Share report">
                <Button
                  kind="secondary"
                  renderIcon={Share}
                  hasIconOnly
                  iconDescription="Share report"
                  data-testid="share-report-button"
                  size="sm"
                  onClick={handleShareClick}
                />
              </Tooltip>
            )}
          </div>
        )}
        {reportConfig.configuration.switchers[reportTemplateId] && (
          <>
            <div
              className={`report-controls ${
                isTabsVisible ? 'switcher' : 'no-switcher'
              }`}
            >
              {isTabsVisible && (
                <Tabs
                  selectedIndex={tabIndex}
                  onChange={({ selectedIndex }) => {
                    updateTabIndex(selectedIndex);
                  }}
                >
                  <TabList aria-label="Report tabs">
                    {reportConfig.configuration.switchers[reportTemplateId].map(
                      (tab) => (
                        <Tab
                          key={tab.name}
                          onClick={() =>
                            posthogEvent(reportContentSwitcherClick, {
                              activeTab: tab.name,
                              reportType: url_route,
                              origin: 'report_content_page',
                            })
                          }
                        >
                          {tab.name}
                        </Tab>
                      )
                    )}
                  </TabList>
                </Tabs>
              )}
            </div>
            <div
              ref={(el) => {
                if (visualRefs?.current && el) {
                  visualRefs.current[ALL_VISUALS_KEY] = el;
                }
              }}
            >
              <div
                className={`fullscreen-container fullscreen-${
                  fullscreenVisual !== null
                }`}
                data-testid={`fullscreen-${fullscreenVisual !== null}`}
                ref={fullscreenRef}
              >
                <ConditionalWrapper
                  condition={conditionalWrapperProps.condition}
                  wrapper={conditionalWrapperProps.wrapper}
                >
                  {!hideSharedDropdowns && (
                    <SharedDropdowns
                      tab={tabIndex}
                      visualIds={
                        reportConfig?.configuration?.switchers?.[
                          reportTemplateId
                        ][tabIndex]?.visualContent
                      }
                      globalSelections={globalSelections}
                    />
                  )}
                  <Grid data-testid="visuals-grid">
                    {visualMetaData
                      .filter((vis: Visual) => {
                        return (
                          fullscreenVisual === null ||
                          isVisualFullscreen(vis, fullscreenVisual)
                        );
                      })
                      .map((vis: Visual) => {
                        return (
                          <Fragment key={vis.id}>
                            {fullscreenVisual === null &&
                              navigationTabs[0]?.id === vis.id &&
                              getNavigationTabs(vis)}
                            <VisualContainer
                              visual={vis}
                              reportType={report_type}
                              reportTemplateId={reportTemplateId}
                              reportTemplateIndex={reportTemplateIndex}
                              globalSelections={globalSelections}
                              headerPrefix={headerPrefix}
                            />
                          </Fragment>
                        );
                      })}
                  </Grid>
                </ConditionalWrapper>
              </div>
            </div>
          </>
        )}
      </Fetch>
    </>
  );
};

export default ReportContent;
