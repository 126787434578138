import { utils, writeFile } from 'xlsx-js-style';
import { DateTime } from 'luxon';
import { Button } from '@carbon/react';
import { Warning, Download } from '@carbon/icons-react';
import { hasInvalidItems } from '../../../utils/attributeUtils';
import type { UploadData } from '../../../reducers/AttributeValuesReducer';
import type { FunctionComponent } from 'react';
import type { HeaderData, RowData } from './AttributeValuesGrid';

interface AttributeValuesErrorNotificationsProps {
  uploadData: UploadData;
  headerData: HeaderData[];
  rowData: RowData[];
}
const AttributeValuesErrorNotifications: FunctionComponent<
  AttributeValuesErrorNotificationsProps
> = ({ uploadData, headerData, rowData }) => {
  const downloadSkus = () => {
    const workbook = utils.book_new();
    const headers = headerData
      .filter((item) => item.key !== 'product')
      .map((item) => item.header);

    const rows = rowData.map((row) =>
      headers.map((header) => {
        const key = headerData.find((h) => h.header === header)?.key;
        return key ? row[key] : '';
      })
    );

    const worksheet = utils.aoa_to_sheet([headers, ...rows]);

    worksheet['!cols'] = headers.map(() => ({ wch: 30 }));

    utils.book_append_sheet(workbook, worksheet);
    const currentDate = DateTime.now().toFormat('dd-MM-yyyy');
    writeFile(workbook, `Custom_Attribute_Invalid_SKUs_${currentDate}.csv`);
  };

  return (
    <div className="AttributeValuesGrid__notification-wrapper">
      <div>
        {hasInvalidItems(uploadData?.products) && (
          <div className="AttributeValuesGrid__notification">
            <Warning size={18} className="AttributeValuesGrid__warning-icon" />
            Product not found in your database. Please re-upload the file with
            SKUs so they match existing product ID’s.
          </div>
        )}
        {hasInvalidItems(uploadData?.attributes) && (
          <div className="AttributeValuesGrid__notification">
            <Warning size={18} className="AttributeValues__warning-icon" />
            Attribute name doesn’t match any existing attribute group name.
            Please re-upload the file with the correct attribute name as the
            column header.
          </div>
        )}
      </div>
      <div className="AttributeValuesGrid__notification-button">
        <Button
          kind="secondary"
          renderIcon={Download}
          onClick={downloadSkus}
          size="sm"
          disabled={false}
          className="has-icon"
        >
          Download list of Invalid SKUS
        </Button>
      </div>
    </div>
  );
};

export default AttributeValuesErrorNotifications;
