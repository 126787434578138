import type { SkuLevelPayload } from '../types/featureFlag';

export interface FeatureFlag {
  name: string;
  payload?:
    | {
        title: string;
        text: string;
        duration?: {
          endTime: string;
        };
        banner_ids?: number[];
      }
    | { user_group_ids: number[] }
    | SkuLevelPayload
    | string[];
}
export interface PosthogState {
  featureFlags: FeatureFlag[];
}

export const initialPosthogState: PosthogState = {
  featureFlags: [],
};

interface UpdateFeatureFlags {
  type: 'UPDATE_FEATURE_FLAGS';
  featureFlags: FeatureFlag[];
}

export type PosthogAction = UpdateFeatureFlags;

const posthogReducer = (
  state: PosthogState,
  action: PosthogAction
): PosthogState => {
  if (action.type === 'UPDATE_FEATURE_FLAGS') {
    return {
      ...state,
      featureFlags: action.featureFlags,
    };
  } else {
    return state;
  }
};

export default posthogReducer;
